import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { getImageUrl, BlockContent } from 'sanity';

const MainItem = ({ name, image, text, id, styles, theme }) => {
  const imageUrl = getImageUrl(image);

  const { color } = styles;

  return (
    <div
      className={classNames('main__item-wrapper', {
        'main__item-wrapper--dark': theme === 'dark',
      })}
    >
      <div className="main__item-separator main__item-separator--left"></div>
      <Link className="main__item" to={`/${id}`}>
        <h3 className="main__title">{name}</h3>
        <div
          style={{ backgroundImage: `url(${imageUrl})` }}
          alt=""
          className="main__image"
        />
        <div className="main__text" style={{ color }}>
          <BlockContent blocks={text} />
        </div>
      </Link>
      <div className="main__item-separator main__item-separator--right"></div>
    </div>
  );
};

export default MainItem;
