import { useEffect, useRef, useState } from 'react';
import Masonry from 'react-masonry-css';
import classNames from 'classnames';
import Lightbox from 'react-image-lightbox';

const Gallery = ({ gallery, theme }) => {
  const [category, setCategory] = useState(gallery[0].id);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const counter = useRef(0);

  useEffect(() => {
    const currentCategory = gallery.filter((cat) => cat.id === category)[0];

    setPhotos(currentCategory.photos);
  }, [gallery, category]);

  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= photos.length) {
      setLoading(false);
    }
  };

  const handleGalleryChange = (categoryId) => {
    if (categoryId === category) return;

    setLoading(true);
    setCategory(categoryId);

    counter.current = 0;
  };

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const renderMenu = () =>
    gallery.map((cat) => {
      const { name, id } = cat;

      return (
        <button
          key={`gallery-menu-${id}`}
          className={classNames('gallery__menu-item', {
            'gallery__menu-item--active': category === id,
            'gallery__menu-item--dark': theme === 'dark',
          })}
          onClick={() => handleGalleryChange(id)}
        >
          {name}
        </button>
      );
    });

  const renderPhotos = () => {
    return photos.map((photo, index) => (
      <img
        key={`gallery-image-${index}`}
        src={photo.thumbnail}
        alt=""
        className="gallery__image"
        onLoad={imageLoaded}
        onClick={() => openLightbox(index)}
      />
    ));
  };
  return (
    <section className="gallery">
      <div className="gallery__menu">{renderMenu()}</div>

      <div
        className={classNames('gallery__loading', {
          'gallery__loading--hidden': !loading,
        })}
      >
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <Masonry
        className={classNames('gallery__content', {
          'gallery__content--hidden': loading,
        })}
        breakpointCols={3}
        columnClassName="gallery__column"
      >
        {renderPhotos()}
      </Masonry>

      {isOpen && (
        <Lightbox
          mainSrc={photos[photoIndex].full}
          nextSrc={photos[(photoIndex + 1) % photos.length].full}
          prevSrc={
            photos[(photoIndex + photos.length - 1) % photos.length].full
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
          imagePadding={100}
        />
      )}
    </section>
  );
};

export default Gallery;
