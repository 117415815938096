import { useRef, useEffect } from 'react';
import Slider from 'react-slick';

import sliderArrowLeft from 'assets/images/slider-arrow-left.svg';
import sliderArrowRight from 'assets/images/slider-arrow-right.svg';

import MainItem from './MainItem';

const Main = ({ items, mainItemStyles, theme }) => {
  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView(true);
  }, []);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slider = useRef(null);

  const goPrevSlide = () => {
    slider.current.slickPrev();
  };

  const goNextSlide = () => {
    slider.current.slickNext();
    console.log(slider);
  };

  return (
    <main className="main">
      <img
        src={sliderArrowLeft}
        alt=""
        className="main__slider-nav main__slider-prev"
        onClick={goPrevSlide}
      />
      <div className="main__wrapper">
        <Slider className="main__slider" {...settings} ref={slider}>
          {items.map((item, index) => (
            <MainItem
              {...item}
              key={`index1-${index}`}
              styles={mainItemStyles}
              theme={theme}
            />
          ))}
        </Slider>
      </div>
      <img
        src={sliderArrowRight}
        alt=""
        className="main__slider-nav main__slider-next"
        onClick={goNextSlide}
      />
    </main>
  );
};

export default Main;
