//external
import classNames from "classnames";
import { useState } from "react";

//assets
import headerArrow from "assets/images/header-arrow.svg";
import langEN from "assets/images/languages-en.png";
import langPL from "assets/images/languages-pl.png";
import logo from "assets/images/logo-red.svg";
import themeDark from "assets/images/theme-dark.png";
import themeLight from "assets/images/theme-light.png";

const Header = ({ theme, setTheme, lang, setLang }) => {
  const [isActive, setIsActive] = useState(false);

  const renderLangButton = () => {
    const image = lang === "pl" ? langPL : langEN;
    const otherLAng = lang === "pl" ? "en" : "pl";

    return (
      <button
        className="header__button header__lang"
        onClick={() => setLang(otherLAng)}
      >
        <img
          src={image}
          alt=""
          className="header__button-image header__lang-image"
        />
      </button>
    );
  };

  const renderThemeButton = () => {
    const image = theme === "light" ? themeLight : themeDark;
    const otherTheme = theme === "light" ? "dark" : "light";

    return (
      <button
        className="header__button header__theme"
        onClick={() => setTheme(otherTheme)}
      >
        <img
          src={image}
          alt=""
          className="header__button-image header__theme-image"
        />
      </button>
    );
  };

  const handlePancakeToggle = () => {
    setIsActive(!isActive);
  };

  // const handlePancakeClose = () => {
  //   setIsActive(false);
  // };

  return (
    <header
      className={classNames({ header: true, "header--active": isActive })}
    >
      <div className="header__content">
        <p className="header__text">
          Działalność{" "}
          <span className="header__text--white">RED</span>{" "}
          została zarejestrowana w 1996 roku i funkcjonuje nieprzerwanie do
          dziś. Jesteśmy małą, ale skuteczną i niezależną firmą rodzinną, która
          przez 25 lat zbiera i modeluje swoje doświadczenia. Nasi Klienci
          kojarzą nas ze sprawnością technologiczną, tempem działań, otwartością
          na pomysły, a przede wszystkim z jakością. Niektórzy – po latach
          współpracy – stają się naszymi Przyjaciółmi!
          {/* <br />
          <br />
          RED Monika Brankiewicz
          <br />
          Orła Białego 28/4
          <br />
          78-449 Borne Sulinowo
          <br />
          <br />
          <a href="tel:+48694136749" className="header__phone">
            +48 694 136 749
          </a>
          <br />
          <a href="mailto:biuro@red.media.pl" className="header__phone">
            biuro@red.media.pl
          </a> */}
          <br />
          <br />
        </p>
        <p
          className="header__text"
          style={{
            textAlign: "right",
            alignSelf: "flex-end",
            marginRight: "20%",
          }}
        >
          <span className="header__text--white">NIP</span> PL6731378710
          <br />
          <span className="header__text--white">REGON</span> 331357036
        </p>
      </div>
      <div className="header__strip">
        <div className="header__strip-wrapper">
          {renderThemeButton()}
          {renderLangButton()}
        </div>
      </div>
      <div className="header__wrapper">
        <div className="header__logo-wrapper" onClick={handlePancakeToggle}>
          <img src={logo} alt="" className="header__logo" />
        </div>
        <img
          src={headerArrow}
          alt=""
          className="header__arrow"
          onClick={handlePancakeToggle}
        />
      </div>
    </header>
  );
};

export default Header;
