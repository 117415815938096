//external
import { useState, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
// import { TransitionGroup, CSSTransition } from 'react-transition-group';

//api
import { getSections } from "sanity";

//components
import Header from "components/Header/Header";
import Main from "components/Main/Main";
import Footer from "components/Footer/Footer";
import Subpage from "../Subpage/Subpage";

//assets
import backgroundImageLight from "assets/images/bg-dots-light.png";
import backgroundImageDark from "assets/images/bg-dots-dark.png";

const themes = {
  light: {
    backgroundColor: "transparent",
    backgroundImage: backgroundImageLight,
    color: "black",
    footer: {
      backgroundColor: "#c6c6c6",
      color: "#000000",
    },
    mainItem: {
      color: "black",
    },
  },
  dark: {
    backgroundColor: "#141414",
    backgroundImage: backgroundImageDark,
    color: "white",
    footer: {
      backgroundColor: "#252525",
      color: "#ffffff",
    },
    mainItem: {
      color: "white",
    },
  },
};

const App = () => {
  const [sections, setSections] = useState([]);
  const [theme, setTheme] = useState(localStorage.getItem("theme") ?? "light");
  const [lang, setLang] = useState("pl");
  const location = useLocation();

  useEffect(() => {
    const fetchSections = async () => {
      const sections = await getSections();

      setSections(sections);
    };

    fetchSections();
  }, []);

  const { backgroundColor, backgroundImage, color, footer, mainItem } =
    themes[theme];

  const renderSections = () =>
    sections.map((section) => (
      <Route path={`/${section.id}`} key={`section-${section.id}`}>
        <Subpage items={sections} theme={theme} />
      </Route>
    ));

  const handleSetTheme = (theme) => {
    localStorage.setItem("theme", theme);
    setTheme(theme);
  };

  return (
    <div>
      {sections.length !== 0 && (
        <div
          className="app"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundColor,
            color,
          }}
        >
          <Header
            setTheme={handleSetTheme}
            theme={theme}
            setLang={setLang}
            lang={lang}
          />
          {/* <TransitionGroup>
            <CSSTransition timeout={300} classNames="fade" key={location.key}> */}
          <Switch location={location}>
            <Route exact path="/">
              <Main items={sections} mainItemStyles={mainItem} theme={theme} />
            </Route>
            {renderSections()}
          </Switch>
          {/* </CSSTransition>
          </TransitionGroup> */}
          <Footer styles={footer} theme={theme} setTheme={handleSetTheme} />
        </div>
      )}
    </div>
  );
};

export default App;
