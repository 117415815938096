import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { BlockContent, getImageUrl } from 'sanity';
import Gallery from '../Gallery/Gallery';

import leftArrow from 'assets/images/slider-arrow-left.svg';

const Subpage = ({ items, theme }) => {
  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView(true);
  }, []);

  const { pathname } = useLocation();
  const pageData = items.filter((item) => `/${item.id}` === pathname)[0];

  const renderGallery = () => {
    if (pageData.gallery) {
      return (
        <Gallery
          title={pageData.name}
          gallery={pageData.gallery}
          theme={theme}
        />
      );
    }
  };

  return (
    <div className="subpage">
      <div className="subpage__wrapper">
        <h2 className="subpage__header">{pageData.name}</h2>
        <div className="subpage__content">
          <Link to="/" className="subpage__back-wrapper">
            <img className="subpage__back" src={leftArrow} alt="" />
          </Link>
          <div
            className="subpage__image"
            style={{ backgroundImage: `url(${getImageUrl(pageData.image)})` }}
          ></div>
          <div className="subpage__text">
            <BlockContent blocks={pageData.text} />
          </div>
        </div>
        <div
          className={classNames('subpage__line', {
            'subpage__line--light': theme === 'dark',
          })}
        ></div>
        {renderGallery()}
      </div>
    </div>
  );
};

export default Subpage;
