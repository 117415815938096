import laptopLight from 'assets/images/laptop-light.png';
import laptopDark from 'assets/images/laptop-dark.png';
import phoneIcon from 'assets/images/phone-icon.png';
import emailIcon from 'assets/images/email-icon.png';
import envelopeIcon from 'assets/images/envelope-icon.png';

const Footer = ({ styles, theme, setTheme }) => {
  const { backgroundColor, color } = styles;

  const renderLaptop = () => {
    const laptopSrc = theme === 'light' ? laptopDark : laptopLight;
    const otherTheme = theme === 'light' ? 'dark' : 'light';

    return (
      <div
        alt=""
        className="footer__laptop"
        style={{ backgroundImage: `url(${laptopSrc})` }}
        onClick={() => setTheme(otherTheme)}
      />
    );
  };

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__header" style={{ backgroundColor }}>
          {renderLaptop()}
          <p className="footer__title">Kontakt</p>
          <div className="footer__content footer__content--top">
            <div className="footer__title footer__title--lowercase">
              <p className="footer__title-text">telefon</p>
              <img className="footer__title-icon" src={phoneIcon} alt="" />
            </div>
            <div className="footer__title footer__title--lowercase">
              <p className="footer__title-text">e-mail</p>
              <img className="footer__title-icon" src={emailIcon} alt="" />
            </div>
            <div className="footer__title footer__title--lowercase">
              <p className="footer__title-text">adres</p>
              <img className="footer__title-icon" src={envelopeIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="footer__footer">
          <div className="footer__content">
            <div
              className="footer__title footer__title--lowercase"
              style={{ color }}
            >
              <p className="footer__title-text">telefon</p>
              <img className="footer__title-icon" src={phoneIcon} alt="" />
            </div>
            <a
              className="footer__text footer__text-link"
              href="tel:+48 694 136 749"
            >
              <span>+48 694 136 749</span>
            </a>
            <div
              className="footer__title footer__title--lowercase"
              style={{ color }}
            >
              <p className="footer__title-text">e-mail</p>
              <img className="footer__title-icon" src={emailIcon} alt="" />
            </div>
            <a
              className="footer__text footer__text-link"
              href="mailto:biuro@red.media.pl"
            >
              <span>biuro@red.media.pl</span>
            </a>
            <div
              className="footer__title footer__title--lowercase"
              style={{ color }}
            >
              <p className="footer__title-text">adres</p>
              <img className="footer__title-icon" src={envelopeIcon} alt="" />
            </div>
            <p className="footer__text">
              <span>
                RED Monika Brankiewicz
                <br />
                ul. Orła Białego 28/4
                <br />
                78-449 Borne Sulinowo
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
