import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContentParser from '@sanity/block-content-to-react';

const client = sanityClient({
  projectId: '3wqdsjsd',
  dataset: 'production',
  useCdn: true,
});

const imageBuilder = imageUrlBuilder(client);

export const getImageUrl = (source) => {
  if (!source) return;
  return imageBuilder.image(source);
};

export const getSections = async () => {
  const data = await client.fetch(
    `*[_type == "section"]{
        ...
    }`
  );

  transformGalleries(data);
  const sortedSections = sortSections(data);

  return sortedSections;
};

export const BlockContent = BlockContentParser;

export default client;

const transformGalleries = (sections) => {
  sections.forEach((section, sectionIndex) => {
    section.gallery &&
      section.gallery.forEach((category, categoryIndex) => {
        category.photos &&
          category.photos.forEach((photo, photoIndex) => {
            sections[sectionIndex].gallery[categoryIndex].photos[photoIndex] = {
              full: getImageUrl(photo.photo).url(),
              thumbnail: getImageUrl(photo.photo).width(400).url(),
            };
          });
      });
  });

  return sections;
};

const sortSections = (sections) => {
  return sections.sort((a, b) => (a.order > b.order ? 1 : -1));
};
